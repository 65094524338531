<template>
  <div class="card">
    <div class="card-body pb-0">
      <div class="d-flex align-items-start">
        <div class="flex-grow-1">
          <h5 class="card-title mb-3">
            Visão geral
          </h5>
        </div>
        <div class="flex-shrink-0" />
      </div>

      <div class="mb-3 row">
        <label
          for="filter-start-at"
          class="col-md-2 col-form-label"
        >
          Intervalo de:
        </label>
        <div class="col-md-3">
          <input
            id="filter-start-at"
            v-model="filterStartAt"
            class="form-control"
            type="date"
          >
        </div>
        <label
          for="filter-end-at"
          class="col-md-1 col-form-label text-center"
        >
          até:
        </label>
        <div class="col-md-3">
          <input
            id="filter-end-at"
            v-model="filterEndAt"
            class="form-control"
            type="date"
          >
        </div>
        <div class="col-md-3 pt-2">
          <div class="dropdown">
            <a
              class="dropdown-toggle text-reset"
              href="#"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="fw-semibold"> Classificar por: </span>
              <span class="text-muted">
                {{ groupByOptions[groupBy] }}
                <i class="mdi mdi-chevron-down ms-1" /></span>
            </a>

            <div class="dropdown-menu dropdown-menu-end">
              <a
                v-for="(label, value) in groupByOptions"
                :key="value"
                class="dropdown-item"
                @click="groupBy = value"
              > {{ label }} </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row gy-4">
        <div class="col-xxl-3">
          <div>
            <div class="mt-3 mb-3">
              <p class="text-muted mb-1">
                Faturamento no período
              </p>

              <div class="d-flex flex-wrap align-items-center gap-2">
                <h2 class="mb-0">
                  {{ $n(totalBilled, 'currency') }}
                </h2>
                <!-- <div
                  class="badge rounded-pill font-size-13"
                  :class="rate <= 0 ? 'badge-soft-danger': 'badge-soft-success'"
                >
                  {{ rate }}%
                </div> -->
              </div>
            </div>

            <div class="row g-0">
              <div class="col-sm-6">
                <div class="border-bottom border-end p-3 h-100">
                  <p class="text-muted text-truncate mb-1">
                    Pedidos
                  </p>
                  <h5 class="text-truncate mb-0">
                    {{ totalOrders }}
                  </h5>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="border-bottom p-3 h-100">
                  <p class="text-muted text-truncate mb-1">
                    Clientes
                  </p>
                  <h5 class="text-truncate mb-0">
                    {{ totalCustomers }}
                  </h5>
                </div>
              </div>
            </div>
            <div
              v-if="false"
              class="row g-0"
            >
              <div class="col-sm-6">
                <div class="border-bottom border-end p-3 h-100">
                  <p class="text-muted text-truncate mb-1">
                    Order Value
                  </p>
                  <h5 class="text-truncate mb-0">
                    12.03 %
                  </h5>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="border-bottom p-3 h-100">
                  <p class="text-muted text-truncate mb-1">
                    Customers
                  </p>
                  <h5 class="text-truncate mb-0">
                    21,456
                  </h5>
                </div>
              </div>
            </div>
            <div
              v-if="false"
              class="row g-0"
            >
              <div class="col-sm-6">
                <div class="border-end p-3 h-100">
                  <p class="text-muted text-truncate mb-1">
                    Income
                  </p>
                  <h5 class="text-truncate mb-0">
                    $35,652
                  </h5>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="p-3 h-100">
                  <p class="text-muted text-truncate mb-1">
                    Expenses
                  </p>
                  <h5 class="text-truncate mb-0">
                    $12,248
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-9">
          <div>
            <apexchart
              height="350"
              type="bar"
              :options="overviewChartOptions"
              :series="overviewSeries"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- end card body -->
  </div>
</template>

<script>
import i18n from '@/i18n'
import { onMounted, ref, watch } from 'vue'
import { dashboard } from './api'
import dayjs from 'dayjs'

const defaultOverviewChartOptions = {
  chart: {
    type: 'bar',
    height: 350,
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '35%',
      borderRadius: 6,
      endingShape: 'rounded'
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  colors: ['#fff', '#fff'],
  xaxis: {
    categories: []
  },
  yaxis: {
    labels: {
      formatter: function (value, { seriesIndex }) {
        if (seriesIndex === 1) {
          return i18n.global.n(value, 'currency')
        }
        return value
      }
    },
    tickAmount: 4
  },
  legend: {
    show: false
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'vertical',
      shadeIntensity: 1,
      inverseColors: true,
      gradientToColors: ['#f1f3f7', '#3b76e1'],
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 38, 100, 38]
    }
  }
}

const groupByOptions = {
  day: 'Dia',
  week: 'Semana',
  month: 'Mês',
  year: 'Ano'
}

export default {
  setup () {
    const totalBilled = ref(0)
    const totalOrders = ref(0)
    const totalCustomers = ref(0)
    const overviewSeries = ref([])
    const overviewChartOptions = ref(defaultOverviewChartOptions)
    const groupBy = ref('day')
    const rate = ref(0)

    const format = 'YYYY-MM-DD HH:mm:ss'
    const startAt = dayjs().startOf('month').format(format)
    const endAt = dayjs().format(format)

    const filterStartAt = ref(startAt.substring(0, 10))
    const filterEndAt = ref(endAt.substring(0, 10))

    const fetchData = () => {
      dashboard({
        billing: {
          between: [
            filterStartAt.value + ' 00:00:00',
            filterEndAt.value + ' 23:59:59'
          ].join(','),
          group_by: groupBy.value
        // previous: true
        }
      }).then((data) => {
        const { current, previous } = data.billing
        const counts = current.map(({ count }) => count)
        const amounts = current.map(({ amount }) => parseFloat(amount))
        const categories = current.map(({ date }) => {
          return date.split('-').reverse().join('/')
        })

        overviewChartOptions.value = {
          ...defaultOverviewChartOptions,
          ...{
            xaxis: {
              categories
            }
          }
        }

        totalBilled.value = amounts.reduce((a, c) => {
          a += c
          return a
        }, 0)
        totalOrders.value = counts.reduce((a, c) => {
          a += c
          return a
        }, 0)
        totalCustomers.value = current.reduce((a, c) => {
          a += c.customers
          return a
        }, 0)

        overviewSeries.value = [
          { name: 'Pedidos', data: counts },
          { name: 'Valor', data: amounts }
        ]

        const previousAmount = previous.reduce((a, c) => {
          a += parseFloat(c.amount)
          return a
        }, 0)

        rate.value = (((totalBilled.value / previousAmount) * 100) - 100).toFixed(2)

        if (isNaN(rate.value)) {
          rate.value = 0
        }
      })
    }

    watch([groupBy, filterStartAt, filterEndAt], () => {
      fetchData()
    })

    onMounted(() => {
      fetchData()
    })

    return {
      overviewChartOptions,
      overviewSeries,
      totalBilled,
      totalOrders,
      totalCustomers,
      groupByOptions,
      groupBy,
      filterStartAt,
      filterEndAt,
      rate
    }
  }
}
</script>
