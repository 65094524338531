<template>
  <div class="row">
    <h5 class="card-title m-2">
      Números obtidos entre {{ $d(startAt, 'short') }} e {{ $d(endAt, 'short') }}
      <small class="text-info">
        Comparados com o mesmo período do mês anterior
        ({{ $d(prevStartAt, 'short') }} a {{ $d(prevEndAt, 'short') }})
      </small>
    </h5>

    <div
      v-for="(data, key) in totals"
      :key="key"
      class="col col-lg-4 col-md-6 col-sm-12"
    >
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0 me-3">
              <div class="avatar">
                <div class="avatar-title rounded bg-primary bg-gradient">
                  <eva-icon
                    :name="data.icon"
                    class="fill-white"
                  />
                </div>
              </div>
            </div>
            <div class="flex-grow-1">
              <p class="text-muted mb-1">
                {{ data.label }}
              </p>
              <h4 class="mb-0">
                {{ data.value }}
              </h4>
            </div>

            <div class="flex-shrink-0 align-self-end ms-2">
              <div
                class="badge rounded-pill font-size-13"
                :class="data.rate <= 0 ? 'badge-soft-danger' : 'badge-soft-success'"
              >
                {{ data.rate > 0 ? '+' : '' }}{{ data.rate }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { onMounted, ref } from 'vue'
import { dashboard } from './api'
import i18n from '@/i18n'

export default {
  setup () {
    const totals = ref({
      revenue: {
        label: 'Receita',
        icon: 'pie-chart-2',
        value: 0,
        rate: 0,
        previous: null
      },
      orders: {
        label: 'Pedidos',
        icon: 'shopping-bag',
        value: 0,
        rate: 0,
        previous: null
      },
      customers: {
        label: 'Novos clientes',
        icon: 'people',
        value: 0,
        rate: 0,
        previous: null
      }
    })

    const format = 'YYYY-MM-DD HH:mm:ss'

    const startAt = dayjs().startOf('month').format(format)
    const endAt = dayjs().endOf('day').format(format)

    const prevStartAt = dayjs()
      .startOf('month')
      .subtract(1, 'month')
      .format(format)

    const prevEndAt = dayjs()
      .startOf('month')
      .endOf('day')
      .subtract(1, 'month')
      .set('date', dayjs().date())
      .format(format)

    const calculateRate = (current, previous) => {
      const diff = current - previous
      const diffPercent = ((diff / previous) * 100)
      return isNaN(diffPercent) ? 0 : diffPercent.toFixed(2)
    }

    onMounted(() => {
      dashboard({
        billing: {
          between: [startAt, endAt].join(','),
          group_by: 'month',
          previous: [prevStartAt, prevEndAt].join(',')
        },
        customers: {
          between: [startAt, endAt].join(','),
          previous: [prevStartAt, prevEndAt].join(',')
        }
      }).then((data) => {
        const billing = data?.billing || null
        const customers = data?.customers || null
        if (billing && billing.current[0]) {
          const currentAmount = parseFloat(billing.current[0].amount)
          const previousAmount = parseFloat(billing.previous[0] ? billing.previous[0].amount : 0)

          const rateAmount = calculateRate(currentAmount, previousAmount)

          totals.value.revenue.value = i18n.global.n(currentAmount, 'currency')
          totals.value.revenue.previous = i18n.global.n(previousAmount, 'currency')
          totals.value.revenue.rate = rateAmount

          totals.value.orders.value = billing.current[0].count
          totals.value.orders.previous = billing.previous[0] ? billing.previous[0].count : 0
          totals.value.orders.rate = calculateRate(
            totals.value.orders.value,
            totals.value.orders.previous
          )
        }

        if (customers && customers.current[0]) {
          totals.value.customers.value = customers.current[0].count
          totals.value.customers.previous = customers.previous[0].count
          totals.value.customers.rate = calculateRate(
            totals.value.customers.value,
            totals.value.customers.previous
          )
        }
      })
    })

    return {
      totals,
      startAt,
      endAt,
      prevStartAt,
      prevEndAt
    }
  }
}
</script>
