<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-start">
        <div class="flex-grow-1">
          <h5 class="card-title mb-3">
            Pedidos por status
            <small class="text-info">hoje</small>
          </h5>
        </div>
        <div
          v-if="false"
          class="flex-shrink-0"
        >
          <div class="dropdown">
            <a
              class="dropdown-toggle text-muted"
              href="#"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                class="fill-muted"
                data-eva-height="18"
                data-eva-width="18"
              />
            </a>

            <div class="dropdown-menu dropdown-menu-end">
              <a
                class="dropdown-item"
                href="#"
              >Yearly</a>
              <a
                class="dropdown-item"
                href="#"
              >Monthly</a>
              <a
                class="dropdown-item"
                href="#"
              >Weekly</a>
              <a
                class="dropdown-item"
                href="#"
              >Today</a>
            </div>
          </div>
        </div>
      </div>

      <apexchart
        class="mt-2"
        height="220"
        type="donut"
        :options="orderChartOptions"
        :series="orderSeries"
      />

      <div class="text-center mt-4 border-top">
        <div class="row">
          <div
            v-for="(data, key) in statuses"
            :key="key"
            class="col-3"
          >
            <div
              class="pt-3"
              :style="{ color: data.color }"
            >
              <p class="text-muted-- text-truncate mb-2">
                {{ data.label }}
              </p>
              <h5
                class="font-size-16 mb-0"
                :style="{ color: data.color }"
              >
                {{ data.count }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { ref } from 'vue'
import { dashboard } from './api'

const format = 'YYYY-MM-DD HH:mm:ss'
const defaultOrderSeries = [0, 0, 0, 0]
const defaultOrderChartOptions = {
  chart: {
    height: 220,
    type: 'donut'
  },
  plotOptions: {
    pie: {
      donut: {
        size: '70%'
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  labels: [],
  colors: [],
  fill: {
    type: 'gradient'
  },
  legend: {
    show: false,
    position: 'bottom',
    horizontalAlign: 'center',
    verticalAlign: 'middle',
    floating: false,
    fontSize: '14px',
    offsetX: 0
  }
}

export default {
  setup () {
    const statuses = ref({
      CONFIRMED: {
        label: 'Confirmado',
        count: 0,
        color: '#63ad6f'
      },
      PROCESSING: {
        label: 'Processando',
        count: 0,
        color: '#3b76e1'
      },
      PENDING: {
        label: 'Pendente',
        count: 0,
        color: '#f9c256'
      },
      CANCELED: {
        label: 'Cancelado',
        count: 0,
        color: '#f56e6e'
      }
    })

    const orderChartOptions = ref({
      ...defaultOrderChartOptions,
      ...{
        labels: Object.values(statuses.value).map(({ label }) => label),
        colors: Object.values(statuses.value).map(({ color }) => color)
      }
    })

    const orderSeries = ref(defaultOrderSeries)

    const startAt = dayjs().startOf('day')
    const endAt = startAt.clone().endOf('day')

    dashboard({
      orders: {
        created_at: {
          gte: startAt.format(format),
          lte: endAt.format(format)
        }
      }
    }).then((data) => {
      const current = data.orders.current ?? {}
      for (const item of current) {
        const index = Object.keys(statuses.value).indexOf(item.status)
        statuses.value[item.status].count = item.count
        orderSeries.value.splice(index, 1, item.count)
      }
    })

    return {
      orderChartOptions,
      orderSeries,
      statuses
    }
  }
}
</script>
