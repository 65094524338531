<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-start">
        <div class="flex-grow-1">
          <h5 class="card-title mb-3">
            Acesso de usuários
          </h5>
        </div>
        <!-- <div class="flex-shrink-0">
          <div class="dropdown">
            <a
              class="dropdown-toggle text-muted"
              href="#"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Weekly<i class="mdi mdi-chevron-down ms-1"></i>
            </a>

            <div class="dropdown-menu dropdown-menu-end">
              <a class="dropdown-item" href="#">Yearly</a>
              <a class="dropdown-item" href="#">Monthly</a>
              <a class="dropdown-item" href="#">Weekly</a>
              <a class="dropdown-item" href="#">Today</a>
            </div>
          </div>
        </div> -->
      </div>

      <div>
        <p class="text-muted mb-1">
          Últimos sete dias
          <small class="text-info"> Comparados com os sete dias anteriores </small>
        </p>
        <h4>
          {{ totalUsers }}
        </h4>
      </div>

      <div class="m-n3">
        <apexchart
          height="270"
          type="area"
          :options="activityChartOptions"
          :series="activitySeries"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { ref } from 'vue'
import { dashboard } from './api'

const format = 'YYYY-MM-DD HH:mm:ss'

const defaultActivitySeries = ref([
  {
    name: 'Período atual',
    data: []
  },
  {
    name: 'Período anterior',
    data: []
  }
])

const defaultActivityChartOptions = {
  chart: {
    height: 270,
    type: 'area',
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: 2
  },
  colors: ['#3b76e1', '#f56e6e'],
  legend: {
    show: true,
    position: 'top',
    horizontalAlign: 'right'
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [20, 100, 100, 100]
    }
  },
  yaxis: {
    tickAmount: 4
  },
  xaxis: {
    categories: []
  }
}

const weekDays = [
  'Dom',
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sáb'
]

export default {
  setup () {
    const totalUsers = ref(0)
    const activitySeries = ref(defaultActivitySeries)
    const activityChartOptions = ref(defaultActivityChartOptions)

    const endAt = dayjs().endOf('day')
    const prevEndAt = endAt.clone().subtract(1, 'week')
    const prevStartAt = prevEndAt.clone().subtract(1, 'week').add(1, 'second')
    const startAt = prevEndAt.clone().add(1, 'second')

    dashboard({
      visitors: {
        between: [
          startAt.format(format),
          endAt.format(format)
        ].join(','),
        previous: [
          prevStartAt.format(format),
          prevEndAt.format(format)
        ].join(',')
      }
    }).then((data) => {
      const currentCount = data.visitors.current.map(({ count }) => count)
      const previousCount = data.visitors.previous.map(({ count }) => count)

      const categories = data.visitors.current.map(({ date }) => {
        const d = dayjs(date, 'YYYY-MM-DD')
        return weekDays[d.day()]
      })

      activitySeries.value[0].data = currentCount
      activitySeries.value[1].data = previousCount

      activityChartOptions.value = {
        ...defaultActivityChartOptions,
        ...{
          xaxis: {
            categories
          }
        }
      }

      totalUsers.value = data.visitors.current.reduce((a, c) => {
        a += c.count
        return a
      }, 0)
    })

    return {
      totalUsers,
      activitySeries,
      activityChartOptions
    }
  }
}
</script>
