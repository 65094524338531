<template>
  <Layout :pagetitle="pagetitle">
    <div class="row">
      <div class="col-xxl-12">
        <Totals />
        <Overview />

        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title mb-3">
                  Contas de recebimento (<small>Ordenadas pela data de alteração de credenciais mais recente</small>)
                </h5>
              </div>
              <div class="card-body">
                <bank-accounts />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-6">
            <UserActivity />
          </div>
          <div class="col-xl-6 col-md-6">
            <OrderStatus />
          </div>
          <div
            v-if="false"
            class="col-xl-4 col-md-6"
          >
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-start">
                  <div class="flex-grow-1">
                    <h5 class="card-title mb-3">
                      Top Product
                    </h5>
                  </div>
                  <div class="flex-shrink-0">
                    <div class="dropdown">
                      <a
                        class="dropdown-toggle text-muted"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Monthly<i class="mdi mdi-chevron-down ms-1" />
                      </a>

                      <div class="dropdown-menu dropdown-menu-end">
                        <a
                          class="dropdown-item"
                          href="#"
                        >Yearly</a>
                        <a
                          class="dropdown-item"
                          href="#"
                        >Monthly</a>
                        <a
                          class="dropdown-item"
                          href="#"
                        >Weekly</a>
                        <a
                          class="dropdown-item"
                          href="#"
                        >Today</a>
                      </div>
                    </div>
                  </div>
                </div>

                <SimpleBar
                  class="mx-n4"
                  data-simplebar
                  style="max-height: 296px"
                >
                  <ul class="list-unstyled mb-0">
                    <li class="px-4 py-3">
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                          <div class="avatar-sm">
                            <div
                              class="avatar-title bg-primary bg-gradient rounded"
                            >
                              #1
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden">
                          <p class="text-muted mb-1 text-truncate">
                            Polo blue T-shirt
                          </p>
                          <div class="fw-semibold font-size-15">
                            $ 25.4
                          </div>
                        </div>
                        <div class="flex-shrink-0">
                          <h5
                            class="font-size-14 mb-0 text-truncate w-xs bg-light p-2 rounded text-center"
                          >
                            3.82k
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li class="px-4 py-3">
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                          <div class="avatar-sm">
                            <div
                              class="avatar-title bg-primary bg-gradient rounded"
                            >
                              #2
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden">
                          <p class="text-muted mb-1 text-truncate">
                            Hoodie for men
                          </p>
                          <div class="fw-semibold font-size-15">
                            $ 24.5
                          </div>
                        </div>
                        <div class="flex-shrink-0">
                          <h5
                            class="font-size-14 mb-0 text-truncate w-xs bg-light p-2 rounded text-center"
                          >
                            3.14k
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li class="px-4 py-3">
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                          <div class="avatar-sm">
                            <div
                              class="avatar-title bg-primary bg-gradient rounded"
                            >
                              #3
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden">
                          <p class="text-muted mb-1 text-truncate">
                            Red color Cap
                          </p>
                          <div class="fw-semibold font-size-15">
                            $ 22.5
                          </div>
                        </div>
                        <div class="flex-shrink-0">
                          <h5
                            class="font-size-14 mb-0 text-truncate w-xs bg-light p-2 rounded text-center"
                          >
                            2.84k
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li class="px-4 py-3">
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                          <div class="avatar-sm">
                            <div
                              class="avatar-title bg-primary bg-gradient rounded"
                            >
                              #4
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden">
                          <p class="text-muted mb-1 text-truncate">
                            Pocket T-shirt
                          </p>
                          <div class="fw-semibold font-size-15">
                            $ 21.5
                          </div>
                        </div>
                        <div class="flex-shrink-0">
                          <h5
                            class="font-size-14 mb-0 text-truncate w-xs bg-light p-2 rounded text-center"
                          >
                            2.06k
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </SimpleBar>
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
      <div v-if="false" class="col-xxl-3">
        <div class="user-sidebar">
          <div class="card">
            <div class="card-body p-0">
              <div class="user-profile-img">
                <img
                  src="@/assets/images/pattern-bg.jpg"
                  class="profile-img profile-foreground-img rounded-top"
                  style="height: 120px"
                  alt=""
                >
                <div class="overlay-content rounded-top">
                  <div>
                    <div class="user-nav p-3">
                      <div class="d-flex justify-content-end">
                        <div class="dropdown">
                          <a
                            class="dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <eva-icon
                              name="more-horizontal-outline"
                              data-eva-width="20"
                              data-eva-height="20"
                              class="fill-white"
                            />
                          </a>

                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                              >Action</a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                              >Another action</a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                              >Something else here</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end user-profile-img -->

              <div class="mt-n5 position-relative">
                <div class="text-center">
                  <img
                    src="@/assets/images/users/avatar-1.jpg"
                    alt=""
                    class="avatar-xl rounded-circle img-thumbnail"
                  >

                  <div class="mt-3">
                    <h5 class="mb-1">
                      Jennifer Bennett
                    </h5>
                    <p class="text-muted">
                      Product Designer
                    </p>
                  </div>
                </div>
              </div>

              <div class="p-3">
                <div class="row text-center pb-3">
                  <div class="col-6 border-end">
                    <div class="p-1">
                      <h5 class="mb-1">
                        1,269
                      </h5>
                      <p class="text-muted mb-0">
                        Products
                      </p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="p-1">
                      <h5 class="mb-1">
                        5.2k
                      </h5>
                      <p class="text-muted mb-0">
                        Followers
                      </p>
                    </div>
                  </div>
                </div>

                <hr class="mb-4">

                <div class="mb-4">
                  <div class="d-flex align-items-start">
                    <div class="flex-grow-1">
                      <h5 class="card-title mb-3">
                        Earning
                      </h5>
                    </div>
                    <div>
                      <button
                        class="btn btn-link py-0 shadow-none"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        data-bs-trigger="hover"
                        title="Info"
                      >
                        <eva-icon
                          name="info-outline"
                          class="fill-muted"
                          data-eva-height="20"
                          data-eva-width="20"
                        />
                      </button>
                    </div>
                  </div>

                  <apexchart
                    height="162"
                    type="radialBar"
                    :options="chartOptions"
                    :series="series"
                  />

                  <div class="text-center mt-4">
                    <h4>$26,256</h4>
                    <p class="text-muted">
                      Earning this Month
                    </p>
                    <div
                      class="d-flex align-items-start justify-content-center gap-2"
                    >
                      <div
                        class="badge rounded-pill font-size-13 badge-soft-success"
                      >
                        + 2.65%
                      </div>
                      <div class="text-muted text-start text-truncate">
                        From previous period
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="mb-4">
                <SimpleBar
                  class="px-4 mx-n3"
                  data-simplebar
                  style="height: 258px"
                >
                  <div>
                    <h5 class="card-title mb-3">
                      Recent Activity
                    </h5>

                    <ul class="list-unstyled mb-0">
                      <li class="py-2">
                        <div class="d-flex align-items-start">
                          <div class="flex-shrink-0 me-3">
                            <div
                              class="avatar-md h-auto p-1 py-2 bg-light rounded"
                            >
                              <div class="text-center">
                                <h5 class="mb-0">
                                  12
                                </h5>
                                <div>Sep</div>
                              </div>
                            </div>
                          </div>
                          <div class="flex-grow-1 pt-2 text-muted">
                            <p class="mb-0">
                              Responded to need “Volunteer Activities"
                            </p>
                          </div>
                        </div>
                      </li>

                      <li class="py-2">
                        <div class="d-flex align-items-start">
                          <div class="flex-shrink-0 me-3">
                            <div
                              class="avatar-md h-auto p-1 py-2 bg-light rounded"
                            >
                              <div class="text-center">
                                <h5 class="mb-0">
                                  11
                                </h5>
                                <div>Sep</div>
                              </div>
                            </div>
                          </div>
                          <div class="flex-grow-1 pt-2 text-muted">
                            <p class="mb-0">
                              Everyone realizes would be desirable...
                              <a href="javascript: void(0);">Read more</a>
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="py-2">
                        <div class="d-flex align-items-start">
                          <div class="flex-shrink-0 me-3">
                            <div
                              class="avatar-md h-auto p-1 py-2 bg-light rounded"
                            >
                              <div class="text-center">
                                <h5 class="mb-0">
                                  10
                                </h5>
                                <div>Sep</div>
                              </div>
                            </div>
                          </div>
                          <div class="flex-grow-1 pt-2 text-muted">
                            <p class="mb-0">
                              Joined the group “Boardsmanship Forum”
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="pt-2">
                        <a
                          href="#"
                          class="btn btn-link w-100 shadow-none"
                        ><i class="mdi mdi-loading mdi-spin me-2" /> Load
                          More</a>
                      </li>
                    </ul>
                  </div>
                </SimpleBar>
              </div>
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div v-if="false" class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-start">
              <div class="flex-grow-1">
                <h5 class="card-title mb-3">
                  Ratings & Reviews
                </h5>
              </div>
              <div class="flex-shrink-0">
                <div class="dropdown">
                  <a
                    class="dropdown-toggle text-muted"
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <eva-icon
                      name="more-horizontal-outline"
                      class="fill-muted"
                      data-eva-height="18"
                      data-eva-width="18"
                    />
                  </a>

                  <div class="dropdown-menu dropdown-menu-end">
                    <a
                      class="dropdown-item"
                      href="#"
                    >Yearly</a>
                    <a
                      class="dropdown-item"
                      href="#"
                    >Monthly</a>
                    <a
                      class="dropdown-item"
                      href="#"
                    >Weekly</a>
                    <a
                      class="dropdown-item"
                      href="#"
                    >Today</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row gy-4 gx-0">
              <div class="col-lg-6">
                <div>
                  <div class="text-center">
                    <h1>4.3</h1>
                    <div class="font-size-16 mb-1">
                      <i class="mdi mdi-star text-warning" />
                      <i class="mdi mdi-star text-warning" />
                      <i class="mdi mdi-star text-warning" />
                      <i class="mdi mdi-star text-warning" />
                      <i class="mdi mdi-star-half-full text-warning" />
                    </div>
                    <div class="text-muted">
                      (14,254 Based)
                    </div>
                  </div>

                  <div class="mt-4">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <div class="p-1 py-2">
                          <h5 class="font-size-16 mb-0">
                            5 <i class="mdi mdi-star" />
                          </h5>
                        </div>
                      </div>
                      <div class="col">
                        <div class="p-1 py-2">
                          <div
                            class="progress animated-progess custom-progress"
                          >
                            <div
                              class="progress-bar bg-gradient bg-primary"
                              role="progressbar"
                              style="width: 90%"
                              aria-valuenow="90"
                              aria-valuemin="0"
                              aria-valuemax="90"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="p-1 py-2">
                          <h5 class="font-size-16 mb-0">
                            50%
                          </h5>
                        </div>
                      </div>
                    </div>
                    <!-- end row -->

                    <div class="row align-items-center">
                      <div class="col-auto">
                        <div class="p-1 py-2">
                          <h5 class="font-size-16 mb-0">
                            4 <i class="mdi mdi-star" />
                          </h5>
                        </div>
                      </div>
                      <div class="col">
                        <div class="p-1 py-2">
                          <div
                            class="progress animated-progess custom-progress"
                          >
                            <div
                              class="progress-bar bg-gradient bg-primary"
                              role="progressbar"
                              style="width: 75%"
                              aria-valuenow="75"
                              aria-valuemin="0"
                              aria-valuemax="75"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="p-1 py-2">
                          <h5 class="font-size-16 mb-0">
                            20%
                          </h5>
                        </div>
                      </div>
                    </div>
                    <!-- end row -->

                    <div class="row align-items-center">
                      <div class="col-auto">
                        <div class="p-1 py-2">
                          <h5 class="font-size-16 mb-0">
                            3 <i class="mdi mdi-star" />
                          </h5>
                        </div>
                      </div>
                      <div class="col">
                        <div class="p-1 py-2">
                          <div
                            class="progress animated-progess custom-progress"
                          >
                            <div
                              class="progress-bar bg-gradient bg-primary"
                              role="progressbar"
                              style="width: 60%"
                              aria-valuenow="60"
                              aria-valuemin="0"
                              aria-valuemax="60"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="p-1 py-2">
                          <h5 class="font-size-16 mb-0">
                            15%
                          </h5>
                        </div>
                      </div>
                    </div>
                    <!-- end row -->

                    <div class="row align-items-center">
                      <div class="col-auto">
                        <div class="p-1 py-2">
                          <h5 class="font-size-16 mb-0">
                            2 <i class="mdi mdi-star" />
                          </h5>
                        </div>
                      </div>
                      <div class="col">
                        <div class="p-1 py-2">
                          <div
                            class="progress animated-progess custom-progress"
                          >
                            <div
                              class="progress-bar bg-gradient bg-warning"
                              role="progressbar"
                              style="width: 50%"
                              aria-valuenow="50"
                              aria-valuemin="0"
                              aria-valuemax="50"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-auto">
                        <div class="p-1 py-2">
                          <h5 class="font-size-16 mb-0">
                            10%
                          </h5>
                        </div>
                      </div>
                    </div>
                    <!-- end row -->

                    <div class="row align-items-center">
                      <div class="col-auto">
                        <div class="p-1 py-2">
                          <h5 class="font-size-16 mb-0">
                            1 <i class="mdi mdi-star" />
                          </h5>
                        </div>
                      </div>
                      <div class="col">
                        <div class="p-1 py-2">
                          <div
                            class="progress animated-progess custom-progress"
                          >
                            <div
                              class="progress-bar bg-gradient bg-danger"
                              role="progressbar"
                              style="width: 20%"
                              aria-valuenow="20"
                              aria-valuemin="0"
                              aria-valuemax="20"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="p-1 py-2">
                          <h5 class="font-size-16 mb-0">
                            5%
                          </h5>
                        </div>
                      </div>
                    </div>
                    <!-- end row -->
                  </div>
                </div>
              </div>
              <!-- end col -->

              <div class="col-lg-6">
                <div class="ps-lg-4">
                  <div class="d-flex flex-wrap align-items-start gap-3">
                    <h5 class="font-size-15">
                      Reviews:
                    </h5>
                    <p class="text-muted">
                      (14,254 Based)
                    </p>
                  </div>

                  <div
                    class="me-lg-n3 pe-lg-3"
                    data-simplebar
                    style="max-height: 266px"
                  >
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <div>
                          <div class="d-flex align-items-start">
                            <div class="flex-grow-1">
                              <div class="badge bg-success bg-gradient mb-2">
                                <i class="mdi mdi-star" /> 4.1
                              </div>
                            </div>
                            <div class="flex-shrink-0">
                              <p class="text-muted font-size-13">
                                12 Jul, 21
                              </p>
                            </div>
                          </div>

                          <p class="text-muted mb-4">
                            It will be as simple as in fact, It will seem like
                            simplified
                          </p>
                          <div class="d-flex align-items-start">
                            <div class="flex-grow-1">
                              <h5 class="font-size-14 mb-0">
                                Samuel
                              </h5>
                            </div>

                            <div class="flex-shrink-0">
                              <div class="hstack gap-3">
                                <div
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Like"
                                >
                                  <a
                                    href="#"
                                    class="text-muted"
                                  ><i class="mdi mdi-thumb-up-outline" /></a>
                                </div>
                                <div class="vr" />
                                <div
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Comment"
                                >
                                  <a
                                    href="#"
                                    class="text-muted"
                                  ><i class="mdi mdi-comment-text-outline" /></a>
                                </div>
                                <div class="vr" />
                                <div class="dropdown">
                                  <a
                                    class="text-muted dropdown-toggle"
                                    href="#"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i class="mdi mdi-dots-horizontal" />
                                  </a>

                                  <div
                                    class="dropdown-menu dropdown-menu-right"
                                  >
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                    >Action</a>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                    >Another action</a>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                    >Something else here</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div>
                          <div class="d-flex align-items-start">
                            <div class="flex-grow-1">
                              <div class="badge bg-success bg-gradient mb-2">
                                <i class="mdi mdi-star" /> 4.0
                              </div>
                            </div>
                            <div class="flex-shrink-0">
                              <p class="text-muted font-size-13">
                                06 Jul, 21
                              </p>
                            </div>
                          </div>
                          <p class="text-muted mb-4">
                            Sed ut perspiciatis iste error sit
                          </p>
                          <div class="d-flex align-items-start">
                            <div class="flex-grow-1">
                              <h5 class="font-size-14 mb-0">
                                Joseph
                              </h5>
                            </div>

                            <div class="flex-shrink-0">
                              <div class="hstack gap-3">
                                <div
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Like"
                                >
                                  <a
                                    href="#"
                                    class="text-muted"
                                  ><i class="mdi mdi-thumb-up-outline" /></a>
                                </div>
                                <div class="vr" />
                                <div
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Comment"
                                >
                                  <a
                                    href="#"
                                    class="text-muted"
                                  ><i class="mdi mdi-comment-text-outline" /></a>
                                </div>
                                <div class="vr" />
                                <div class="dropdown">
                                  <a
                                    class="text-muted dropdown-toggle"
                                    href="#"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i class="mdi mdi-dots-horizontal" />
                                  </a>

                                  <div
                                    class="dropdown-menu dropdown-menu-right"
                                  >
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                    >Action</a>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                    >Another action</a>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                    >Something else here</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li class="list-group-item">
                        <div>
                          <div class="d-flex align-items-start">
                            <div class="flex-grow-1">
                              <div class="badge bg-success bg-gradient mb-2">
                                <i class="mdi mdi-star" /> 4.2
                              </div>
                            </div>
                            <div class="flex-shrink-0">
                              <p class="text-muted font-size-13">
                                26 Jun, 21
                              </p>
                            </div>
                          </div>
                          <p class="text-muted mb-4">
                            Neque porro quisquam est, qui dolorem ipsum quia
                            dolor sit amet
                          </p>
                          <div class="d-flex align-items-start">
                            <div class="flex-grow-1">
                              <h5 class="font-size-14 mb-0">
                                Paul
                              </h5>
                            </div>

                            <div class="flex-shrink-0">
                              <div class="hstack gap-3">
                                <div
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Like"
                                >
                                  <a
                                    href="#"
                                    class="text-muted"
                                  ><i class="mdi mdi-thumb-up-outline" /></a>
                                </div>
                                <div class="vr" />
                                <div
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Comment"
                                >
                                  <a
                                    href="#"
                                    class="text-muted"
                                  ><i class="mdi mdi-comment-text-outline" /></a>
                                </div>
                                <div class="vr" />
                                <div class="dropdown">
                                  <a
                                    class="text-muted dropdown-toggle"
                                    href="#"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i class="mdi mdi-dots-horizontal" />
                                  </a>

                                  <div
                                    class="dropdown-menu dropdown-menu-right"
                                  >
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                    >Action</a>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                    >Another action</a>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                    >Something else here</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li class="list-group-item">
                        <div>
                          <div class="d-flex align-items-start">
                            <div class="flex-grow-1">
                              <div class="badge bg-success bg-gradient mb-2">
                                <i class="mdi mdi-star" /> 4.1
                              </div>
                            </div>
                            <div class="flex-shrink-0">
                              <p class="text-muted font-size-13">
                                24 Jun, 21
                              </p>
                            </div>
                          </div>
                          <p class="text-muted mb-4">
                            Ut enim ad minima veniam, quis nostrum ullam
                            corporis suscipit consequatur nisi ut
                          </p>
                          <div class="d-flex align-items-start">
                            <div class="flex-grow-1">
                              <h5 class="font-size-14 mb-0">
                                Patrick
                              </h5>
                            </div>

                            <div class="flex-shrink-0">
                              <div class="hstack gap-3">
                                <div
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Like"
                                >
                                  <a
                                    href="#"
                                    class="text-muted"
                                  ><i class="mdi mdi-thumb-up-outline" /></a>
                                </div>
                                <div class="vr" />
                                <div
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Comment"
                                >
                                  <a
                                    href="#"
                                    class="text-muted"
                                  ><i class="mdi mdi-comment-text-outline" /></a>
                                </div>
                                <div class="vr" />
                                <div class="dropdown">
                                  <a
                                    class="text-muted dropdown-toggle"
                                    href="#"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i class="mdi mdi-dots-horizontal" />
                                  </a>

                                  <div
                                    class="dropdown-menu dropdown-menu-right"
                                  >
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                    >Action</a>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                    >Another action</a>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                    >Something else here</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main.vue'
import appConfig from '@/app.config'
import { SimpleBar } from 'simplebar-vue3'
import Overview from './overview.vue'
import Totals from './totals.vue'
import UserActivity from './user-activity.vue'
import OrderStatus from './order-status.vue'
import BankAccounts from '@/views/pages/sellers/BankAccounts'

/**
 * Ecommerce component
 */
export default {
  page: {
    title: 'Ecommerce',
    meta: [
      {
        name: 'description',
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    SimpleBar,
    Overview,
    Totals,
    UserActivity,
    OrderStatus,
    BankAccounts
  },
  data () {
    return {
      pagetitle: 'Supra Sorte',

      chartOptions: {
        chart: {
          type: 'radialBar',
          height: 162,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#f3f2f9',
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: false,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2
              }
            },
            hollow: {
              margin: 15,
              size: '65%'
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '22px'
              }
            }
          }
        },
        stroke: {
          lineCap: 'round'
        },
        grid: {
          padding: {
            top: -10
          }
        },
        colors: ['#3b76e1'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          }
        },
        labels: ['Average Results']
      },
      series: [76]
    }
  }
}
</script>
